
html, body {
    height: 100%;
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
 }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-card {
  margin: 1.5rem;
}
